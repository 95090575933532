@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Spectral:200i,300,300i,400,500,700,800&subset=latin-ext');

@import './normalize.less';
@import './reset.less';
@import './general.less';

.font-montserrat {font-family: 'Montserrat', sans-serif;}
.font-spectral {font-family: 'Spectral', serif;}

@color-gold: #bda834;
@color-pink: #ffcbcb;
@color-pink-dark: #d39380;
@color-blue: #485163;
@color-blue-background: #475564;
@color-dark: #231f20;

body, html {
    font-size: 16px;
    .font-spectral;
    color: @color-dark;
    @media only screen and (max-width: 1050px) {
		font-size: 15px;
    }
    @media only screen and (max-width: 750px) {
		font-size: 14px;
    }
}
body {
	@media only screen and (max-width: 700px) {
    	padding-top: 100px;
    }
}

.inner-content {
	max-width: 1250px;
	margin: auto;
	&.wide {
		max-width: 1450px;
	}
	@media only screen and (max-width: 1800px) {
		&.wide {
			max-width: calc(~"100% - 220px");
			margin-left: 220px;
			margin-right: auto;
		}
	}
	@media only screen and (max-width: 1700px) {
		max-width: calc(~"100% - 220px");
		margin-left: 220px;
		margin-right: 50px;
	}
	@media only screen and (max-width: 1050px) {
		margin-right: 0px;
		padding: 0px 10px;
		&.wide {
			margin-right: 0px;
			padding: 0px 10px;
		}
	}
	@media only screen and (max-width: 900px) {
		max-width: 100%;
		margin-left: 185px;
		&.wide {
			margin-left: 185px;
			max-width: 100%;
		}
	}
	@media only screen and (max-width: 700px) {
		width: 100%;
		margin: 0px;
		&.wide {
			margin-left: 0px;
			max-width: 100%;
		}
	}
}

#navbar {
	position: fixed;
	left: 0px;
	top: 0px;
	height: 100%;
	box-sizing: border-box;
	transition: top 0.3s ease-in;
	width: 200px;
	margin-top: -50px;
	color: #000;
	@media only screen and (max-width: 1450px) {
		width: 170px;
		font-size: 15px;
	}
	@media only screen and (max-width: 1350px) {
		font-size: 14px;
	}
	@media only screen and (max-width: 900px) {
		width: 160px;
	}
	@media only screen and (max-width: 700px) {
		display: none;
	}
	.inner {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		.wrap {
			width: 100%;
		}
		.logo {
			padding: 0px 10px 30px 5px;
			width: 100%;
			@media only screen and (max-width: 900px) {
				padding: 0px 10px 20px 5px;
			}
			a {
				display: block;
				width: 100%;
				img {
					max-width: 100%;
				}
			}
		}
		.navigation {
			padding-left: 65px;
			box-sizing: border-box;
			@media only screen and (max-width: 1450px) {
				padding-left: 35px;
			}
			@media only screen and (max-width: 900px) {
				padding-left: 30px;
			}
			a {
				.font-montserrat;
				text-align: left;
				text-transform: uppercase;
				display: block;
				padding: 4px 5px;
				margin: 4px 0px;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					bottom: 0px;
					left: 5px;
					height: 2px;
					background-color: @color-gold;
					width: 90%;
					transform: scaleX(0);
					transition: all 0.3s ease;
					transform-origin: left;
					opacity: 0.8;
				}
				&:hover {
					animation: memu-hover-color 1s;
					animation-fill-mode: forwards;
					-webkit-animation-fill-mode: forwards;
					&:after {
						transform: scaleX(1);
					}
				}
			}
		}
		.socials {
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
			padding-left: 65px;
			box-sizing: border-box;
			@media only screen and (max-width: 1450px) {
				padding-left: 35px;
			}
			@media only screen and (max-width: 900px) {
				padding-left: 35px;
			}
			a {
				.font-montserrat;
				text-align: left;
				text-transform: uppercase;
				display: block;
				padding: 4px 5px;
				margin: 4px 0px;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					bottom: 0px;
					left: 0px;
					height: 2px;
					background-color: @color-gold;
					width: 90%;
					transform: scaleX(0);
					transition: all 0.3s ease;
					transform-origin: left;
				}
				&:hover {
					animation: memu-hover-color 1s;
					-webkit-animation-fill-mode: forwards;
					animation-fill-mode: forwards;
					&:after {
						transform: scaleX(1);
					}
				}
			}
		}
	}
}

#mobile-navbar {
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	background-color: #fff;
	height: 100px;
	z-index: 1000;
	@media only screen and (max-width: 700px) {
		display: block;
	}
	.inner {
		position: relative;
		.logo {
			a {
				display: inline-block;
				height: 70px;
				padding-bottom: 15px;
				padding-left: 30px;
				padding-top: 15px;
				@media only screen and (max-width: 400px) {
					padding-left: 15px;
				}
				img {
					height: 70px;
					width: auto;
				}
			}
		}
		.menu-button {
			position: absolute;
			right: 25px;
			top: 30px;
			font-size: 60px;
			font-weight: bold;
			height: 33px; width: 45px;
			cursor: pointer;
			.menu-thing {
				height: 4px;
				width: 100%;
				background-color: @color-dark;
				margin-top: 15px;
				transition: opacity 0.2s ease;
				opacity: 1;
				&:before {
					content: '';
					position: absolute;
					top: 0px; left: 0px; right: 0px;
					height: 4px;
					width: 100%;
					background-color: @color-dark;
				}
				&:after {
					content: '';
					position: absolute;
					bottom: 0px; left: 0px; right: 0px;
					height: 4px;
					width: 100%;
					background-color: @color-dark;
				}
			}
			.close-thing {
				transition: opacity 0.2s ease;
				opacity: 0;
				padding-top: 15px;
				&:before {
					content: '';
					position: absolute;
					bottom: 45%; left: 0px; right: 0px;
					height: 3px;
					width: 100%;
					background-color: @color-dark;
				    transform: rotate(32deg);
				}
				&:after {
					content: '';
					position: absolute;
					bottom: 45%; left: 0px; right: 0px;
					height: 3px;
					width: 100%;
					background-color: @color-dark;
					transform: rotate(-32deg);
				}
			}
		}
		.menu {
			position: fixed;
			top: 100px; right: 0px;
			width: 100%; bottom: 0px;
			background-color: #fff;
			padding: 10px 20px;
			visibility: hidden;
			opacity: 0;
			transition: all 0.2s ease 0.3s;
			overflow: hidden;
			.navigation, .socials {
				padding: 5px 25px;
				a {
					.font-montserrat;
					display: block;
					padding: 4px 0px;
					margin: 4px 0px;
					font-size: 1.6rem;
					line-height: 1.1;
					font-weight: 300;
					text-align: center;
				}
			}
			.navigation {
				transition: all 0.3s ease;
				transform: translateX(-120%);
			}
			.socials {
				transition: all 0.3s ease;
				transform: translateX(-120%);
				position: absolute;
				bottom: 40px;
				left: 0px; right: 0px;
			}
		}
	}
	&.toggled {
		.menu-button {
			// background-color: #666;
			.menu-thing {
				opacity: 0;
			}
			.close-thing {
				opacity: 1;
			}
		}
		.menu {
			visibility: visible;
			opacity: 1;
			transition: all 0.4s ease;
			.navigation, .socials {
				transform: translateX(0);
			}
			.socials {
				transition: transform 0.4s ease 0.7s;
			}
			.navigation {
				transition: transform 0.4s ease 0.4s, border-bottom 0.4s ease 1s;
			}
		}
	}
}

#intro {
	padding-top: 100px;
	background-color: #fff;
	@media only screen and (max-width: 1200px) {
		padding-top: 180px;
	}
	@media only screen and (max-width: 900px) {
		padding-top: 160px;
		padding-bottom: 140px;
	}
	@media only screen and (max-width: 750px) {
		padding-bottom: 120px;
	}
	.inner-content {
		position: relative;
	}
    .notification {
        position: absolute;
        font-size: 1.3rem;
        padding: 0.75rem 4rem;
        text-align: center;
        bottom: 1rem;
        left: 2rem;
        right: 2rem;
        background-color: rgba(255,255,255, 0.9);
        z-index: 15;
        color: #475564;
        font-weight: 700;
    }
	.logo {
		.font-montserrat;
		font-size: 3.8rem;
		font-weight: normal;
		margin: 0;
		text-align: right;
		display: inline-block;
		padding-bottom: 75px;
		padding-right: 125px;
		line-height: 1;
		position: absolute;
		top: 0px; left: 0px;
		z-index: 10;
		background-color: white;
		width: 220px;
		@media only screen and (max-width: 1200px) {
			top: -130px;
			padding-bottom: 215px;
		}
		@media only screen and (max-width: 1050px) {
			padding-right: 75px;
		}
	}
	.center {
		margin-left: 137px;
		margin-right: 220px;
		text-align: right;
		position: relative;
		@media only screen and (max-width: 1300px) {
			margin-right: 135px;
		}
		@media only screen and (max-width: 1200px) {
			margin-right: auto;
			margin-left: 0px;
			max-width: 640px;
		}
		.stripe1 {
			background-color: @color-gold;
			position: absolute;
			top: 0px; left: 0px;
			width: 208px; height: 100%;
			z-index: 5;
		}
		.stripe2 {
			background-color: @color-pink;
			position: absolute;
			top: 95px; left: 0px;
			z-index: 6;
			height: 185px; right: 80px;
			@media only screen and (max-width: 900px) {
				right: 90px;
			}
		}
		.foto {
			position: relative;
			padding-right: 170px;
			@media only screen and (max-width: 900px) {
				padding-right: 40px;
				padding-left: 40px;
			}
			.background {
				background-image: url('../../assets/intro-background.png');
				position: absolute;
				top: 0px; left: 0px;
				width: 100%; height: 100%;
				z-index: 3;
			}
			img {
				z-index: 12;
				// &:hover {animation: intensifies 0.15s infinite;}
				position: relative;
				display: inline-block;
				vertical-align: bottom;
			}
		}
	}
	.motto {
		position: absolute;
		z-index: 14;
		right: 0px; bottom: 180px;
		font-size: 2.3rem;
		color: @color-gold;
		text-align: center;
		line-height: 1.2;
		@media only screen and (max-width: 1200px) {
			bottom: 250px;
		}
		@media only screen and (max-width: 1050px) {
			right: auto;
			left: 275px;
			bottom: 150px;
		}
		@media only screen and (max-width: 900px) {
			left: 0px; right: 0px;
			bottom: -110px;
			line-height: 1.1;
		}
	}
}

.vertical-line-35 {
	height: 35px;
	width: 1px;
	background-color: @color-gold;
	position: relative;
	left: 50%;
	@media only screen and (max-width: 750px) {
		height: 30px;
	}
}
.vertical-line-70 {
	height: 70px;
	width: 1px;
	background-color: @color-gold;
	position: relative;
	left: 50%;
	@media only screen and (max-width: 750px) {
		height: 60px;
	}
}
.vertical-line-170 {
	height: 170px;
	width: 1px;
	background-color: @color-gold;
	position: relative;
	left: 50%;
	@media only screen and (max-width: 750px) {
		height: 140px;
	}
}
.vertical-line-150 {
	height: 150px;
	width: 1px;
	background-color: @color-gold;
	position: relative;
	left: 50%;
	@media only screen and (max-width: 750px) {
		height: 130px;
	}
}
.vertical-line-220 {
	height: 220px;
	width: 1px;
	background-color: @color-gold;
	position: relative;
	left: 50%;
	@media only screen and (max-width: 750px) {
		height: 190px;
	}
}
.vertical-line-330 {
	height: 330px;
	width: 1px;
	background-color: @color-gold;
	position: relative;
	left: 50%;
	@media only screen and (max-width: 750px) {
		height: 260px;
	}
}

#about {
	padding-top: 190px;
	@media only screen and (max-width: 1250px) {
		padding-top: 50px;
	}
	@media only screen and (max-width: 1050px) {
		padding-top: 0px;
	}
	.heading {
		.font-montserrat;
		font-size: 3.1rem;
		font-weight: normal;
		@media only screen and (max-width: 1250px) {
			text-align: center;
			margin: 15px 0px;
			margin-top: 30px;
		}
	}
	.center {
		// padding-left: 137px;
		// padding-right: 220px;
		text-align: center;
		position: relative;
		margin: auto;
		max-width: 850px;
		@media only screen and (max-width: 1250px) {
			margin-right: 40px;
			margin-left: 40px;
			padding-top: 280px;
		}
		@media only screen and (max-width: 500px) {
			margin-right: 20px;
			margin-left: 20px;
			padding-top: 260px;
		}
		.prefex {
			position: relative;
			max-width: 355px;
			margin: auto;
			padding-bottom: 30px;
			.vertical-line-220 {
				position: absolute;
				bottom: calc(~"100% + 50px");
			}
			.pre-text {
				color: @color-gold;
				font-size: 1.6rem;
				font-weight: bold;
				text-align: left;
				line-height: 1.4;
				z-index: 4;
				margin-left: 40px;
				position: relative;
				@media only screen and (max-width: 500px) {
					margin-left: 20px;
				}
			}
			.first-letter {
				position: absolute;
				display: block;
				top: -162px;
			    left: -32px;
				font-size: 17rem;
				font-weight: 800;
				color: #dadce0;
				z-index: 2;
				line-height: 1;
				@media only screen and (max-width: 950px) {
					font-size: 12rem;
					top: -112px; left: -22px;
				}
				@media only screen and (max-width: 500px) {
					left: -15px; top: -90px;
					font-size: 10rem;
				}
			}
		}
		.text {
			p {
				padding: 30px 0px;
				margin: 0px;
				font-size: 1.2rem;
				color: @color-blue;
			}
			.paragraph-logo {
				padding: 30px 0px;
				padding-bottom: 0px;
			}
		}
	}
}

#team {
	overflow: hidden;
	padding: 50px 0px;
	@media only screen and (max-width: 1250px) {
		padding: 40px 0px;
	}
	@media only screen and (max-width: 950px) {
		padding: 30px 0px;
	}
	@media only screen and (max-width: 750px) {
		padding: 20px 0px;
	}
	@media only screen and (max-width: 450px) {
		padding: 10px 0px;
	}
	.heading {
		.font-montserrat;
		font-size: 3.1rem;
		font-weight: normal;
		@media only screen and (max-width: 1250px) {
			text-align: center;
			margin: 15px 0px;
			margin-top: 30px;
		}
	}
	.inner {
		padding-top: 50px;
		padding-left: 40px;
		@media only screen and (max-width: 1800px) {
			padding-left: 0px;
		}
		@media only screen and (max-width: 1050px) {
			padding-top: 10px;
		}
		.clear;
		.left {
			width: 320px;
			float: left;
			@media only screen and (max-width: 1600px) {
				width: 260px;
			}
			@media only screen and (max-width: 1300px) {
				width: 100%;
				float: none;
				padding-bottom: 30px;
				display: flex;
				justify-content: space-around;
				justify-content: space-evenly;
			}
			@media only screen and (max-width: 850px) {
				padding-bottom: 10px;
			}
			.member {
				padding: 10px 0px;
				margin: 10px 0px;
				cursor: pointer;
				@media only screen and (max-width: 1450px) {
					padding: 8px 0px;
					margin: 8px 0px;
				}
				@media only screen and (max-width: 1300px) {
					display: inline-block;
				}
				.number {
					font-size: 2.7rem;
					font-weight: 200;
					font-style: italic;
					color: @color-gold;
					position: relative;
					display: inline-block;
					line-height: 1;
					transition: all 0.3s ease;
					width: 45px;
					vertical-align: middle;
					@media only screen and (max-width: 1450px) {
						font-size: 2.4rem;
						width: 40px;
					}
					@media only screen and (max-width: 1300px) {
						display: block;
						text-align: center;
						width: 100%;
					}
					&:after {
						content: '';
						position: absolute;
						bottom: 0px;
						left: -2px;
						width: 100%;
						height: 2px;
						background-color: @color-blue;
						transform: scaleX(0);
						transition: all 0.3s ease;
						transform-origin: left;
						opacity: 0.8;
					}
				}
				&.active, &:hover {
					.number {
						color: @color-blue;
						font-weight: 700;
						// I think this looks bad:
						// font-style: normal;
						&:after {
							transform: scaleX(1);
						}
					}
					.name {
						color: @color-blue;
						font-size: 1.1rem;
						// font-style: normal;
					}
				}
				.name {
					color: @color-gold;
					font-weight: 300;
					font-style: italic;
					display: inline-block;
					text-transform: uppercase;
					padding-left: 15px;
					transform: scale3d(1,1,1);
					transition: all 0.3s ease;
					@media only screen and (max-width: 1450px) {
						padding-left: 5px;
					}
					@media only screen and (max-width: 1300px) {
						display: none;
					}
				}
			}
		}
		.right {
			float: left;
			width: calc(~"100% - 320px");
			position: relative;
			height: 825px;
			@media only screen and (max-width: 1600px) {
				width: calc(~"100% - 270px");
			}
			@media only screen and (max-width: 1300px) {
				width: 100%;
				float: none;
			}
			@media only screen and (max-width: 1050px) {
				padding-top: 330px;
				box-sizing: content-box;
				height: auto;
			}
			@media only screen and (max-width: 850px) {
				padding-top: 250px;
			}
			.team-member {
				display: none;
				height: 100%;
				&.active {
					display: block;
				}
			}
			.team-info {
				background-color: @color-blue-background;
				width: calc(~"55% + 50px");
				box-sizing: border-box;
				padding: 30px;
				padding-right: 100px;
				color: white;
				z-index: 2;
				position: relative;
				height: 100%;
				@media only screen and (max-width: 1550px) {
					padding: 20px;
					padding-right: 40px;
					width: calc(~"55%");
				}
				@media only screen and (max-width: 1050px) {
					width: 100%;
					padding: 20px;
				}
				.name {
					font-size: 4rem;
					line-height: 1.1;
					max-width: 350px;
					font-weight: bold;
					@media only screen and (max-width: 1450px) {
						font-size: 3.6rem;
					}
					@media only screen and (max-width: 1050px) {
						font-size: 3rem;
					}
				}
				.switcher {
					padding-top: 20px;
					text-transform: uppercase;
					font-size: 1rem;
					@media only screen and (max-width: 1450px) {
						padding-top: 20px;
					}
					div {
						display: inline-block;
						position: relative;
						cursor: pointer;
						&:first-of-type {
							margin-right: 30px;
						}
						&:after {
							transition: all 0.3s ease;
							content: '';
							position: absolute;
							bottom: 0px;
							left: -2px;
							right: -2px;
							height: 2px;
							background-color: white;
							transform: scaleX(0);
							transform-origin: left;
							opacity: 0.8;
						}
						&.active, &:hover {
							&:after{
								transform: scaleX(1);
							}
						}
					}
				}
				.info-content {
					padding-top: 20px;
					font-size: 0.8rem;
					white-space: nowrap;
					overflow: hidden;
					@media only screen and (max-width: 1450px) {
						padding-top: 20px;
					}
					@media only screen and (max-width: 950px) {
						font-size: 0.9rem;
					}
					@media only screen and (max-width: 750px) {
						font-size: 1rem;
					}
					.section {
						transition: all 0.3s ease;
						position: relative;
						width: 100%;
						white-space: normal;
						display: inline-block;
						vertical-align: top;
						transform: translateX(100%);
						&.active {
							&.cenik {
								transform: translateX(-100%);
							}
							&.medailon {
								transform: translateX(0%);
							}
						}
						&.cenik {
							h4 {
								font-size: 0.9rem;
								text-transform: uppercase;
								font-weight: 600;
								margin: 0px; padding: 0px;
								padding-top: 10px;
							}
							.table {
								padding-left: 10px;
								box-sizing: border-box;
								display: table;
								width: 100%;
								.font-montserrat;
								.tabcol {
									display: table-row;
									line-height: 1.2;
									.tabrow {
										display: table-cell;
										width: 50%;
										font-weight: 300;
										padding: 0px 5px;
										box-sizing: border-box;
									}
									.tabrow2 {
										display: table-cell;
										width: 50%;
										box-sizing: border-box;
										text-align: left;
									}
								}
								&.type2 {
									padding-left: 0px;
									padding-top: 10px;
									.tabcol {
										.tabrow {
											padding: 0px;
											padding-right: 5px;
											font-size: 0.9rem;
											font-weight: 600;
											text-transform: uppercase;
											font-weight: 600;
										}
									}
								}
							}
						}
					}
					p {
						padding: 5px 0px;
					}
				}
			}
			.team-foto {
				position: absolute;
				top: -25px;
				right: -150px;
				width: calc(~"45% + 150px");
				height: 100%;
				background-position: top left;
				background-repeat: no-repeat;
				background-size: cover;
				z-index: 3;
				@media only screen and (max-width: 1800px) {
					right: -20px;
					width: calc(~"48%");
				}
				@media only screen and (max-width: 1450px) {
					width: calc(~"49%");
					right: -10px;
					background-size: contain;
				}
				@media only screen and (max-width: 1050px) {
					top: 0px;
					height: 300px;
					background-size: cover;
					width: 100%;
					right: 0px; left: 0px;
					background-position: top 19% center;
				}
				@media only screen and (max-width: 850px) {
					height: 240px;
				}
			}
			.foto-shadow-thing {
				background-image: url('../../assets/intro-background.png');
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				position: absolute;
				top: 45px;
				bottom: -45px;
				right: -240px;
				width: calc(~"45% + 250px");
				z-index: 1;
				@media only screen and (max-width: 1050px) {
					left: 40px;
					right: -150px;
					width: auto;
				}
			}
		}
	}
}

#stendr {
	padding: 90px 0px;
	padding-bottom: 110px;
	@media only screen and (max-width: 1250px) {
		padding: 70px 0px;
		padding-bottom: 90px;
	}
	@media only screen and (max-width: 1150px) {
		padding: 40px 0px;
	}
	.inner {
		position: relative;
		height: 1000px;
		width: 100%;
		padding-top: 50px;
		@media only screen and (max-width: 1500px) {
			height: 800px;
		}
		@media only screen and (max-width: 1150px) {
			height: auto;
			padding-bottom: 390px;
		}
		@media only screen and (max-width: 950px) {
			padding-bottom: 280px;
		}
		@media only screen and (max-width: 800px) {
			padding-bottom: 240px;
		}
		@media only screen and (max-width: 500px) {
			padding-bottom: 0px;
		}
		.background {
			position: absolute;
			top: 120px; bottom: 30px;
			left: 10%; right: 25%;
			background-image: url('../../assets/intro-background.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			@media only screen and (max-width: 1150px) {
				background-image: none;
				position: relative;
				width: 100%;
				text-align: center;
				left: auto;
				right: auto;
				top: 30px;
			}
			.showroom {
				position: absolute;
				.font-montserrat;
				font-size: 2.5rem;
				bottom: 20px; left: 20px;
				font-weight: 700;
				line-height: 1;
				@media only screen and (max-width: 1400px) {
					left: auto;
					right: 50px;
					bottom: 220px;
				}
				@media only screen and (max-width: 1150px) {
					position: relative;
					bottom: auto;
					left: auto; right: auto;
				}
			}
		}
		#stendr_box1 {
			position: absolute;
			top: 40px; right: 210px;
			width: 290px; height: 290px;
		}
		#stendr_box2 {
			position: absolute;
			top: 220px; left: 50px;
			width: 320px; height: 320px;
		}
		#stendr_box3 {
			position: absolute;
			bottom: -60px; left: 360px;
			width: 320px; height: 320px;
		}
		@media only screen and (max-width: 1500px) {
			#stendr_box2 {
				top: 100px;
			}
			#stendr_box3 {
				left: 300px;
			}
		}
		@media only screen and (max-width: 1400px) {
			#stendr_box3 {
				left: 50px;
			}
		}
		@media only screen and (max-width: 1150px) {
			#stendr_box1 {
				bottom: 5px;
				top: auto;
				right: auto;
				left: 0px;
				width: 33%;
				box-sizing: border-box;
				padding: 5px;
				height: 320px;
			}
			#stendr_box2 {
				top: auto;
				bottom: 5px;
				left: 33%;
				width: 33%;
				box-sizing: border-box;
				padding: 5px;
				right: auto;
			}
			#stendr_box3 {
				top: auto;
				bottom: 5px;
				left: 66%;
				width: 33%;
				box-sizing: border-box;
				padding: 5px;
				right: auto;
			}
		}
		@media only screen and (max-width: 950px) {
			#stendr_box1, #stendr_box2, #stendr_box3 {
				max-height: 260px;
			}
		}
		@media only screen and (max-width: 800px) {
			#stendr_box1, #stendr_box2, #stendr_box3 {
				max-height: 230px;
			}
		}
		@media only screen and (max-width: 500px) {
			#stendr_box1, #stendr_box2, #stendr_box3 {
				position: relative;
				top: unset; bottom: unset;
				left: unset; right: unset;
				display: block;
				max-width: 280px;
				margin: auto;
				width: auto;
			}
		}
		.heading {
			position: absolute;
			top: 34%;
			font-size: 12rem;
			.font-montserrat;
			font-weight: 400;
			right: 40px;
			letter-spacing: 10px;
			z-index: 5;
			@media only screen and (max-width: 1700px) {
				font-size: 9rem;
			}
			@media only screen and (max-width: 1500px) {
				font-size: 7rem;
				top: 42%;
			}
			@media only screen and (max-width: 1150px) {
				position: relative;
				top: auto;
				right: auto;
				letter-spacing: 6px;
				text-align: center;
			}
			@media only screen and (max-width: 950px) {
				font-size: 5rem;
			}
			@media only screen and (max-width: 500px) {
				font-size: 3rem;
			}
		}
		.text {
			position: absolute;
			right: 20px; bottom: 100px;
			color: @color-gold;
			max-width: 480px;
			text-align: center;
			p {
				padding: 10px 0px;
				font-size: 1.1rem;
				font-weight: 500;
			}
			@media only screen and (max-width: 1500px) {
				max-width: 460px;
				bottom: 60px;
			}
			@media only screen and (max-width: 1400px) {
				bottom: -20px;
			}
			@media only screen and (max-width: 1150px) {
				position: relative;
				max-width: unset;
				padding-right: 50px;
				left: auto; right: auto;
				bottom: auto;
				padding-top: 50px;
				box-sizing: border-box;
				padding-left: 50px;
			}
			@media only screen and (max-width: 500px) {
				padding: 10px;
				padding-top: 60px;
			}
		}
	}
}

#news {
	padding: 50px 0px;
	@media only screen and (max-width: 1250px) {
		padding: 40px 0px;
	}
	@media only screen and (max-width: 950px) {
		padding: 30px 0px;
	}
	@media only screen and (max-width: 750px) {
		padding: 20px 0px;
	}
	@media only screen and (max-width: 450px) {
		padding: 10px 0px;
	}
	.heading {
		.font-montserrat;
		font-size: 3.1rem;
		font-weight: normal;
		@media only screen and (max-width: 1250px) {
			text-align: center;
			margin: 15px 0px;
			margin-top: 30px;
		}
	}
	.main {
		.clear;
		@media only screen and (max-width: 1050px) {
			position: relative;
		}
		.left {
			width: 50%;
			float: left;
			box-sizing: border-box;
			padding: 30px;
			padding-left: 0px;
			display: inline-block;
			@media only screen and (max-width: 1050px) {
				padding: 10px;
				padding-left: 0px;
				padding-top: 45px;
			}
			@media only screen and (max-width: 750px) {
				width: 100%;
				float: none;
				text-align: center;
				padding: 10px;
			}
			.news-heading {
				font-size: 1.8rem;
				color: @color-dark;
				margin: 0px;
				padding-bottom: 10px;
				@media only screen and (max-width: 1400px) {
					font-size: 1.6rem;
				}
				@media only screen and (max-width: 1050px) {
					position: absolute;
					top: 0px; left: 0px; right: 0px;
				}
				@media only screen and (max-width: 750px) {
					position: relative;
					top: unset; left: unset; right: unset;
				}
			}
			.date {
				font-size: 1.1rem;
				color: @color-pink-dark;
				font-weight: 700;
				.font-montserrat;
				letter-spacing: 1px;
			}
			.text {
				font-size: 1.2rem;
				padding-top: 25px;
				line-height: 1.2;
			}
		}
		.right {
			width: 50%;
			float: left;
			box-sizing: border-box;
			display: inline-block;
			padding: 30px;
			position: relative;
			@media only screen and (max-width: 1050px) {
				padding: 10px;
				margin-top: 50px;
			}
			@media only screen and (max-width: 750px) {
				width: 100%;
				padding: 10px;
				float: none;
				text-align: center;
			}
			.foto-wrap {
				position: relative;
				display: inline-block;
				img {
					background-color: white;
					position: relative;
					z-index: 8;
					display: block;
					@media only screen and (max-width: 750px) {
						background-color: none;
					}
				}
				.item-background {
					z-index: 7;
					position: absolute;
					bottom: -15px; left: -15px;
					width: 100%; height: 100%;
					background-color: @color-gold;
					@media only screen and (max-width: 750px) {
						display: none;
					}
				}
			}
		}
	}
	.more-articles {
		padding-top: 40px;
		display: flex;
		flex-wrap: wrap;
		@media only screen and (max-width: 1400px) {
			padding-top: 30px;
		}
		.clear;
		.article {
			width: 50%;
			float: left;
			visibility: visible;
			transition: all 0.3s ease;
			opacity: 1;
			height: auto;
			@media only screen and (max-width: 1300px) {
				width: 100%;
				float: none;
			}
			@media only screen and (max-width: 1050px) {
				position: relative;
			}
			&.hidden {
				opacity: 0;
				visibility: hidden;
				height: 0px;
			}
			.clear;
			.left {
				width: 50%;
				float: left;
				box-sizing: border-box;
				padding: 30px;
				padding-left: 0px;
				@media only screen and (max-width: 1400px) {
					padding: 20px;
				}
				@media only screen and (max-width: 1300px) {
					padding-left: 0px;
				}
				@media only screen and (max-width: 1050px) {
					padding: 10px;
					padding-left: 0px;
					padding-top: 35px;
				}
				@media only screen and (max-width: 750px) {
					width: 100%;
					float: none;
					text-align: center;
				}
				.news-heading {
					font-size: 1.8rem;
					color: @color-dark;
					margin: 0px;
					padding-bottom: 10px;
					line-height: 1.2;
					@media only screen and (max-width: 1400px) {
						font-size: 1.6rem;
					}
				}
				.date {
					font-size: 1.1rem;
					color: @color-pink-dark;
					font-weight: 700;
					.font-montserrat;
					letter-spacing: 1px;
				}
				.text {
					font-size: 1.2rem;
					padding-top: 25px;
					line-height: 1.2;
				}
			}
			.right {
				width: 50%;
				float: left;
				box-sizing: border-box;
				padding: 30px;
				padding-left: 0px;
				@media only screen and (max-width: 1050px) {
					margin-top: 40px;
				}
				@media only screen and (max-width: 750px) {
					width: 100%;
					float: none;
					margin-top: 10px;
					padding: 10px;
					text-align: center;
				}
			}
		}
	}
	.load-more {
		text-align: center;
		padding-top: 40px;
		.button {
			font-size: 1.9rem;
			color: @color-gold;
			.font-montserrat;
			letter-spacing: 2px;
			cursor: pointer;
		}
	}
}

#kontakt {
	.heading {
		.font-montserrat;
		font-size: 3.1rem;
		font-weight: normal;
		margin-bottom: 0px;
		@media only screen and (max-width: 1250px) {
			text-align: center;
			margin: 15px 0px;
			margin-top: 30px;
		}
	}
	.inner {
		position: relative;
		padding-top: 100px;
		padding-bottom: 60px;
		@media only screen and (max-width: 1050px) {
			padding-top: 60px;
			padding-bottom: 30px;
		}
		.clear;
		.left {
			width: 50%;
			float: left;
			padding-right: 30px;
			box-sizing: border-box;
			@media only screen and (max-width: 1050px) {
				width: 100%;
				float: none;
				text-align: center;
				padding: 0px;
			}
			a, p {
				display: block;
				font-weight: 700;
				font-size: 2rem;
				line-height: 1.2;
			}
			a {
				&:hover {
					animation: memu-hover-color 1s;
					animation-fill-mode: forwards;
				}
			}
			p {
				padding: 20px 0px;
				span {
					display: block;
				}
				&.tiny {
					font-size: 1.2rem;
					font-weight: 400;
				}
				.normal-weight {
					font-weight: 400;
				}
			}
		}
		.stripes {
			position: absolute;
			top: 0px; right: 0px; bottom: 0px;
			background-image: url('../../assets/intro-background.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			width: 40%;
			@media only screen and (max-width: 1050px) {
				display: none;
			}
		}
		.right {
			width: 50%;
			float: left;
			box-sizing: border-box;
			padding: 15px;
			position: relative;
			height: 100%;
			@media only screen and (max-width: 1050px) {
				width: 100%;
				float: none;
				margin-top: 30px;
			}
			#map {
				height: 430px;
				img {
					max-width: unset;
				}
			}
			.container {
				display: block;
				z-index: 5;
				position: relative;
				img {
					display: block;
				}
			}
			.gold-background {
				position: absolute;
				left: 0px;
				top: 0px;
				bottom: 0px;
				background-color: @color-gold;
				width: 140px;
				z-index: 2;
			}
			@media only screen and (max-width: 700px) {
				padding: 0px;
				.gold-background {
					display: none;
				}
			}
		}
	}
}

#article {
	padding: 50px 0px;
	h1 {
		font-weight: 700;
		font-size: 2.5rem;
		text-align: center;
		@media only screen and (max-width: 500px) {
			font-size: 2rem;
		}
	}
	.youtube-video {
		text-align: center;
		margin: auto;
		padding: 40px 0px;
		@media only screen and (max-width: 950px) {
			padding: 30px 0px;
		}
		.large-devices {
			display: inline-block;
			@media only screen and (max-width: 1100px) {
				display: none;
			}
		}
		.only-tablet {
			display: none;
			@media only screen and (max-width: 1100px) and (min-width: 550px) {
				display: inline-block;
			}
		}
		.only-mobile {
			display: none;
			@media only screen and (max-width: 549px) {
				display: inline-block;
			}
		}
	}
	.date {
		font-size: 1rem;
		color: @color-pink-dark;
		font-weight: 700;
		.font-montserrat;
		letter-spacing: 1px;
		text-align: center;
		padding-bottom: 40px;
		@media only screen and (max-width: 950px) {
			padding-bottom: 25px;
		}
	}
	.prefex {
		position: relative;
		max-width: 355px;
		margin: 90px auto;
		margin-top: 160px;
		@media only screen and (max-width: 950px) {
			margin: 70px auto;
		}
		@media only screen and (max-width: 500px) {
			margin: 50px auto;
		}
		.vertical-line-220 {
			position: absolute;
			bottom: calc(~"100% + 50px");
		}
		.pre-text {
			color: @color-gold;
			font-size: 1.6rem;
			font-weight: bold;
			text-align: left;
			line-height: 1.4;
			z-index: 4;
			margin-left: 40px;
			position: relative;
			@media only screen and (max-width: 750px) {
				margin-left: 35px;
			}
		}
		.first-letter {
			position: absolute;
			display: block;
			top: -162px;
		    left: -32px;
			font-size: 17rem;
			font-weight: 800;
			color: #dadce0;
			z-index: 2;
			line-height: 1;
			@media only screen and (max-width: 950px) {
				font-size: 12rem;
				top: -92px; left: -22px;
			}
			@media only screen and (max-width: 500px) {
				left: -15px; top: -80px;
				font-size: 10rem;
			}
		}
	}
	.text {
		padding: 50px 0px;
		text-align: center;
		max-width: 800px;
		margin: auto;
		@media only screen and (max-width: 950px) {
			padding: 30px 0px;
		}
		p {
			padding: 20px 0px;
			margin: 0px;
			font-size: 1.2rem;
			color: @color-blue;
			&:last-of-type {
				padding-bottom: 0px;
			}
		}
		.paragraph-logo {
			padding: 30px 0px;
			padding-bottom: 0px;
		}
	}
	.item-data {
		padding-top: 50px;
		text-align: center;
		max-width: 800px;
		margin: auto;
		@media only screen and (max-width: 950px) {
			padding: 30px 0px;
		}
		.section {
			padding-bottom: 40px;
			@media only screen and (max-width: 500px) {
				padding-bottom: 20px;
			}
			&:last-of-type {
				padding-bottom: 0px;
			}
			h2 {
				font-size: 1.8rem;
				margin: 0px;
			}
			.data-text {
				padding: 30px 0px;
				padding-top: 0px;
				margin: 0px;
				font-size: 1.2rem;
				color: @color-blue;
				@media only screen and (max-width: 950px) {
					padding: 20px 0px;
				}
			}
		}
	}
	.item-ingredients {
		padding: 50px 0px;
		text-align: center;
		max-width: 800px;
		margin: auto;
		@media only screen and (max-width: 950px) {
			padding: 30px 0px;
		}
		h2 {
			font-size: 1.8rem;
			margin: 0px; padding-bottom: 10px;
		}
		.section {
			padding-bottom: 30px;
			&:last-of-type {
				padding-bottom: 0px;
			}
			h3 {
				font-size: 1.3rem;
				color: @color-gold;
				margin: 0px;
			}
			.text {
				padding: 0px;
				font-size: 1.2rem;
				color: @color-blue;
			}
		}
	}
	.gallery {
		padding: 40px 10px;
		text-align: center;
		margin: auto;
		max-width: 800px;
		@media only screen and (max-width: 950px) {
			padding: 30px 10px;
		}
		img {
			padding: 20px;
			display: inline-block;
			box-sizing: border-box;
		}
	}
	.more-articles {
		padding-top: 40px;
		display: flex;
		flex-wrap: wrap;
		@media only screen and (max-width: 1400px) {
			padding-top: 30px;
		}
		.clear;
		.article {
			width: 50%;
			float: left;
			visibility: visible;
			transition: all 0.3s ease;
			opacity: 1;
			height: auto;
			@media only screen and (max-width: 1300px) {
				width: 100%;
				float: none;
			}
			@media only screen and (max-width: 1050px) {
				position: relative;
			}
			&.hidden {
				opacity: 0;
				visibility: hidden;
				height: 0px;
			}
			.clear;
			.left {
				width: 50%;
				float: left;
				box-sizing: border-box;
				padding: 30px;
				padding-left: 0px;
				@media only screen and (max-width: 1400px) {
					padding: 20px;
				}
				@media only screen and (max-width: 1300px) {
					padding-left: 0px;
				}
				@media only screen and (max-width: 1050px) {
					padding: 10px;
					padding-left: 0px;
					padding-top: 35px;
				}
				@media only screen and (max-width: 750px) {
					width: 100%;
					float: none;
					text-align: center;
				}
				.news-heading {
					font-size: 1.8rem;
					color: @color-dark;
					margin: 0px;
					padding-bottom: 10px;
					line-height: 1.2;
					@media only screen and (max-width: 1400px) {
						font-size: 1.6rem;
					}
				}
				.date {
					font-size: 1.1rem;
					color: @color-pink-dark;
					font-weight: 700;
					.font-montserrat;
					letter-spacing: 1px;
				}
				.text {
					font-size: 1.2rem;
					padding-top: 25px;
					line-height: 1.2;
				}
			}
			.right {
				width: 50%;
				float: left;
				box-sizing: border-box;
				padding: 30px;
				padding-left: 0px;
				@media only screen and (max-width: 1050px) {
					margin-top: 40px;
				}
				@media only screen and (max-width: 750px) {
					width: 100%;
					float: none;
					margin-top: 10px;
					padding: 10px;
					text-align: center;
				}
			}
		}
	}
	.load-more {
		text-align: center;
		padding-top: 40px;
		padding-bottom: 80px;
		.button {
			font-size: 1.9rem;
			color: @color-gold;
			.font-montserrat;
			letter-spacing: 2px;
			cursor: pointer;
		}
	}
}

footer {
	padding: 40px 0px;
	font-size: 0.9rem;
}
.content {
	overflow: hidden;
}
.animate, .animate2, .animate-bulk-item {
	transition: all 1s ease;
	&.speed0_5 {
		transition: all 0.5s ease;
	}
	&.speed1 {
		transition: all 1s ease;
	}
	&.speed1_5 {
		transition: all 1.5s ease;
	}
	&.delay0_5 {
		transition-delay: 0.5s;
	}
	&.delay1 {
		transition-delay: 1s;
	}
	&.delay1_5 {
		transition-delay: 1.5s;
	}
	&.delay2 {
		transition-delay: 2s;
	}
	&.delay2_5 {
		transition-delay: 2.5s;
	}
	&.slide-in-left {
		opacity: 0;
		transform: translateX(-100%);
		&.trigger {
			opacity: 1;
			transform: translateX(0);
		}
	}
	&.slide-in-top {
		opacity: 0;
		transform: translateY(-100%);
		&.trigger {
			opacity: 1;
			transform: translateX(0);
		}
	}
	&.slide-in-right {
		opacity: 0;
		transform: translateX(100%);
		&.trigger {
			opacity: 1;
			transform: translateX(0);
		}
	}
	&.slide-in-bottom {
		opacity: 0;
		transform: translateY(100%);
		&.trigger {
			opacity: 1;
			transform: translateX(0);
		}
	}
	&.slide-in-bottom-small {
		opacity: 0;
		transform: translateY(50%);
		&.trigger {
			opacity: 1;
			transform: translateX(0);
		}
	}
	&.grow-height {
		transform: scaleY(0);
		transform-origin: top;
		&.trigger {
			transform: scaleY(1);
		}
	}
	&.grow-width {
		transform: scaleX(0);
		transform-origin: left;
		&.trigger {
			transform: scaleX(1);
		}
	}
	&.grow-width-special {
		transform: scaleX(0.01);
		transform-origin: left;
		&.trigger {
			transform: scaleX(1);
		}
	}
	&.grow-header {
		transform: scaleY(0);
		transform-origin: bottom;
		&.trigger {
			transform: scaleY(1);
		}
	}
	&.appear {
		opacity: 0;
		&.trigger {
			opacity: 1;
		}
	}
	&.paragraph-slide-in-right {
		opacity: 0;
		transform: translateX(100%) skewX(20deg);
		&.trigger {
			opacity: 1;
			transform: translateX(0) skewX(0);
		}
	}
}
