a {
	color: inherit;
}
div {
	box-sizing: border-box;
}
img {max-width: 100%;}

@keyframes memu-hover-color {
  20% {
    color: @color-gold + #111;
  }
  100% {
    color: @color-gold - #222;
  }
}

@keyframes intensifies {
   0% {
	transform: translate(0,0) rotate(0deg);
   }
   20% {
	transform: translate(-9px,5px) rotate(0.1deg);
   }
   40% {
	transform: translate(9px,5px) rotate(-0.1deg);
   }
   60% {
	transform: translate(-9px,-5px) rotate(0.1deg);
   }
   80% {
	transform: translate(9px,-5px) rotate(0.3deg);
   }
   100% {
	transform: translate(12px,8px) rotate(-0.2deg);
   }
}

.clear {
	&:after {
		clear: both;
		content: '';
		display: block;
		visibility: hidden;
	}
}

#cookies {
    position: fixed;
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-top: 1px #000 solid;
    font-size: 12px;
    box-sizing: border-box;
    z-index: 9999;
    bottom: 0;
    left: 0;
    text-align: center;
    p {
        margin: 5px 0;
    }
    .button {
        background: #000;
        color: white;
        padding: 2px 5px;
        margin-left: 10px;
        border: none;
        font-size: 12px;
    }
    .deny-button {
        position: absolute;
        right: 20px;
        bottom: 10px;
        border: 0;
        opacity: 0.5;
        color: contrast(#454545);
    }
}